import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { VIDEO_CARD_TYPES, ARTICLE_CARD_TYPES } from '~/constants/ContentCardTypes';

import throttle from 'lodash/throttle';
import uniqBy from 'lodash/uniqBy';
import get from 'lodash/get';

import difference from 'lodash/difference';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import logEvent from '~/util/analytics';

import { forceCheck } from 'react-lazyload';
import ContentCardList from '~/components/ContentCardList/ContentCardList';
import HeadlinesCard from '~/components/HeadlinesCard/HeadlinesCard';
import StyledComponent from '~/components/Styled/Styled';
import ScoreTicker from '~/containers/ScoreTickerContainer';
import Masonry from 'react-masonry-component';
import FilterMenu, { FilterMenuButton } from '~/components/FilterMenu/FilterMenu';

import Ad, { AdTypes } from '~/containers/AdContainer/AdContainer';
import LoadMoreButton from '~/components/LoadMoreButton/LoadMoreButton';
import { operations as pageDataOperations } from '~/state/pageData';

import getWebUri from '~/util/getWebUri';
import getCardTitle from '~/util/getCardTitle';
import pullArticlesWithLongestHeadlines from '~/util/pullArticlesWithLongestHeadlines';
import masonry from '~/static/css/masonry.css';
import css from './LeagueRiver.scss';

const FILTERS = {
  ALL: 'ALL',
  ARTICLES: 'ARTICLES',
  VIDEOS: 'VIDEOS',
};

const masonryOptions = {
  gutter: `.${masonry.gridGutter}`,
  itemSelector: `.${masonry.gridItem}`,
  columnWidth: `.${masonry.gridSizer}`,
  percentPosition: true,
  transitionDuration: 0,
};

const AdBannerAndWrap = args => {
  const { league } = args;
  return (
    <Fragment>
      <a // eslint-disable-line
        id="wrap-link-left"
        className={`wrap-link ${css.wrapLink} ${css.left} ${css.show}`}
        target="_blank"
      />
      <a // eslint-disable-line
        id="wrap-link-right"
        className={`wrap-link ${css.wrapLink} ${css.right} ${css.show}`}
        target="_blank"
      />
      <div className={css.wrapAdContainer}>
        <Ad leagueSlug={league.slug} adType={AdTypes.WRAP} />
      </div>
      <div className={css.adContainer}>
        <Ad leagueSlug={league.slug} adType={AdTypes.LEADERBOARD} />
      </div>
    </Fragment>
  );
};

const HeadlinesComponent = args => {
  const { headlines, league } = args;
  return (
    <div className={`${masonry.gridItem} ${masonry.gridItemHeadlines}`}>
      <div className="row">
        <div className="col-sm-6 col-md-12">
          <HeadlinesCard
            headlines={headlines
              .map(card => ({
                title: getCardTitle(card),
                contentId: card.id,
                url: getWebUri(card),
                type: card.type,
              }))
              .filter(card => card.title)}
          />
        </div>
        <div className={`${css.facebookPageBox} col-sm-6 col-md-12`}>
          <div className={`${css.bigBoxAdContainer}`}>
            <Ad leagueSlug={league.slug} adType={AdTypes.BIGBOX} />
          </div>
        </div>
      </div>
    </div>
  );
};

const FilterMenuComponent = args => {
  const { league, filterFunc } = args;
  return (
    <div className={`${masonry.gridItem} ${masonry.gridItem2}`}>
      <div className={css.riverHeader}>
        <h2 className={css.relatedNewsHeader}>RELATED NEWS</h2>
        <div className={css.filterMenuWrapper}>
          <FilterMenu
            context="league_river"
            league={league.slug}
            defaultFilter={FILTERS.ALL}
            resetOnTransition
            clickHandler={filterFunc}
          >
            <FilterMenuButton filter={FILTERS.ALL} caption="ALL" />
            <FilterMenuButton filter={FILTERS.ARTICLES} caption="ARTICLES" />
            <FilterMenuButton filter={FILTERS.VIDEOS} caption="VIDEOS" />
          </FilterMenu>
        </div>
      </div>
    </div>
  );
};

const TickerComponent = args => {
  const { league } = args;

  return (
    <div className={css.ticker}>
      <div className={`${css.tickerContainer} ${masonry.gridItem} ${masonry.gridItemFullWidth}`}>
        <ScoreTicker trackEvent={logEvent} height="76px" leagueSlug={league.slug} />
      </div>
    </div>
  );
};

const getRelatedContent = (leagueSlug, riverData, currentCardId) => {
  let headlines = [];
  let relatedContent = [];
  if (!riverData) {
    return [headlines, relatedContent];
  }

  relatedContent = uniqBy(
    riverData.relatedContent.filter(
      contentCard => contentCard.id !== currentCardId && contentCard.data.id !== currentCardId
    ),
    'id'
  );

  if (leagueSlug === 'trending') {
    headlines = pullArticlesWithLongestHeadlines(relatedContent);
    relatedContent = difference(relatedContent, headlines);
  } else {
    headlines = relatedContent.slice(0, 5);
    relatedContent = relatedContent.slice(5);
  }

  return [headlines, relatedContent];
};

class LeagueRiver extends React.Component {
  constructor(props) {
    super(props);

    const [headlines, relatedContent] = getRelatedContent(
      props.league.slug,
      props.riverData,
      props.currentCardId
    );
    this.state = {
      relatedContent,
      headlines,
      filteredRiver: relatedContent,
      activeFilter: FILTERS.ALL,
    };
  }

  async componentDidMount() {
    if (!this.masonry) {
      return;
    }
    this.masonryLayout = throttle(this.masonry.layout.bind(this.masonry), 500);
    window.addEventListener('image-loaded', this.masonryLayout);
    forceCheck();
  }

  async componentWillReceiveProps(nextProps) {
    const nextLeague = nextProps.league.slug;

    this.setRiver(nextLeague, nextProps.riverData, nextProps.currentCardId);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const thisLeague = this.props.league.slug;
    const nextLeague = nextProps.league.slug;

    if (thisLeague !== nextLeague) return true;
    if (this.props.isLoading !== nextProps.isLoading) return true;
    if (this.state.filteredRiver.length !== nextState.filteredRiver.length) return true;
    if (this.props.currentCardId !== nextProps.currentCardId) return true;

    return false;
  }

  componentDidUpdate() {
    forceCheck();
  }

  componentWillUnmount() {
    window.removeEventListener('image-loaded', this.masonryLayout);
  }

  getHeading() {
    const { league } = this.props;
    const mediumName = get(league, 'medium_name', '');

    switch (mediumName) {
      case '':
        return '';
      case 'Top News':
        return 'Top News';
      default:
        return `${mediumName} News`;
    }
  }

  setRiver(leagueSlug, riverData, currentCardId) {
    const [headlines, relatedContent] = getRelatedContent(leagueSlug, riverData, currentCardId);

    this.setState(
      {
        headlines,
        relatedContent,
        activeFilter: leagueSlug !== this.props.league.slug ? FILTERS.ALL : this.state.activeFilter,
      },
      () => this.filterRelatedContent(this.state.activeFilter)
    );
  }

  filterRelatedContent(filterArg = FILTERS.ALL) {
    const riverCards = this.state.relatedContent;

    let typeRegEx;
    let filteredRiver = [];
    switch (filterArg) {
      case FILTERS.ARTICLES:
        typeRegEx = new RegExp(ARTICLE_CARD_TYPES.join('|'));
        filteredRiver = riverCards.filter(card => typeRegEx.test(card.type));
        break;
      case FILTERS.VIDEOS:
        typeRegEx = new RegExp(VIDEO_CARD_TYPES.join('|'));
        filteredRiver = riverCards.filter(card => typeRegEx.test(card.type));
        break;
      default:
        filteredRiver = riverCards;
    }

    this.setState({ filteredRiver, activeFilter: filterArg });
  }

  render() {
    const {
      league,
      children,
      currentCardId,
      riverData,
      isLoading,
      loadMoreLeagueRiver,
      countryCode,
      showTag,
    } = this.props;
    const before = riverData.before;
    const riverCards = this.state.filteredRiver;
    const leaguesToIgnore = ['mma'];

    return (
      <Fragment>
        <AdBannerAndWrap league={league} />
        <div className={css.riverContainer}>
          <h1 className="sr-only">{this.getHeading()}</h1>
          <Masonry
            disableImagesLoaded
            className={masonry.grid}
            options={masonryOptions}
            ref={c => {
              this.masonry = this.masonry || c.masonry;
            }}
          >
            <div className={masonry.gridSizer} />
            <div className={masonry.gridGutter} />
            {!leaguesToIgnore.includes(league.slug) ? <TickerComponent league={league} /> : null}
            <div className={`${masonry.gridItem} ${masonry.gridItem2}`}>{children}</div>
            {!!this.state.relatedContent.length && (
              <Fragment>
                <HeadlinesComponent headlines={this.state.headlines} league={league} />
                <FilterMenuComponent
                  league={league}
                  filterFunc={filter => this.filterRelatedContent(filter)}
                />
                <ContentCardList contentCards={riverCards} showTag={showTag} />
              </Fragment>
            )}
          </Masonry>
          <LoadMoreButton
            onClick={() => {
              loadMoreLeagueRiver({
                league,
                currentCardId,
                before,
                countryCode,
                riverData,
              });
            }}
            isLoading={isLoading}
          />
        </div>
      </Fragment>
    );
  }
}

LeagueRiver.propTypes = {
  league: PropTypes.shape({
    slug: PropTypes.string,
    short_name: PropTypes.string,
    medium_name: PropTypes.string,
    sections: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  riverData: PropTypes.shape({
    relatedContent: PropTypes.array,
    before: PropTypes.string,
  }).isRequired,
  children: PropTypes.node,
  currentCardId: PropTypes.number,
  loadMoreLeagueRiver: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  countryCode: PropTypes.string,
  showTag: PropTypes.bool,
};

LeagueRiver.defaultProps = {
  children: null,
  currentCardId: null,
  isLoading: false,
  countryCode: '',
  showTag: false,
};

LeagueRiver.displayName = 'LeagueRiver';

const mapStateToProps = state => ({
  riverData: state.pageData.riverData,
  isLoading: state.pageData.isLoading,
});

const mapDispatchToProps = dispatch => ({
  loadMoreLeagueRiver: bindActionCreators(pageDataOperations.loadMoreLeagueRiver, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  StyledComponent(LeagueRiver, [css, masonry])
);
