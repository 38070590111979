import React from 'react';
import PropTypes from 'prop-types';
import ContentCard from '~/components/ContentCard/ContentCard';
import StyledComponent from '~/components/Styled/Styled';

import css from '~/components/ContentCardList/ContentCardList.scss';
import masonry from '~/static/css/masonry.css';

const ContentCardList = props => {
  const { contentCards } = props;

  return (
    <div>
      {contentCards.map(card => (
        <div className={masonry.gridItem} key={card.id}>
          <ContentCard contentCard={card} currentCard={false} showTag />
        </div>
      ))}
    </div>
  );
};

ContentCardList.propTypes = {
  contentCards: PropTypes.array,
  showTag: PropTypes.bool,
};
ContentCardList.defaultProps = {
  contentCards: [],
  showTag: false,
};

ContentCardList.displayName = 'ContentCardList';

export default StyledComponent(ContentCardList, [css, masonry]);
