import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import isEqual from 'lodash/isEqual';

import logEvent from '~/util/analytics';
import StyledComponent from '~/components/Styled/Styled';
import css from './FilterMenu.scss';

const trackEvent = (context, league, filter) => {
  logEvent('filter_menu', { type: 'click', context, league, active_filter: filter });
};

class FilterMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { activeFilter: props.defaultFilter };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.league !== nextProps.league && this.props.resetOnTransition)
      this.setState({ activeFilter: this.props.defaultFilter });
  }

  render() {
    const updatedChildren = React.Children.map(this.props.children, child =>
      React.cloneElement(child, {
        activeFilter: this.state.activeFilter,
        btnClickHandler: (filter, caption) => {
          trackEvent(this.props.context, this.props.league, caption);
          this.setState({ activeFilter: filter });
          this.props.clickHandler(filter);
        },
      })
    );

    return (
      <ul className={css.filterMenu} role="presentation">
        {updatedChildren}
      </ul>
    );
  }
}

FilterMenu.propTypes = {
  context: PropTypes.string.isRequired,
  league: PropTypes.string.isRequired,
  defaultFilter: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  clickHandler: PropTypes.func.isRequired,
  resetOnTransition: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.object),
};

FilterMenu.defaultProps = {
  resetOnTransition: false,
  children: null,
};

FilterMenu.displayName = 'FilterMenu';

export const FilterMenuButton = props => {
  const cssContext = classnames.bind(css);
  const classes = cssContext({
    filterMenuButton: true,
    selected: isEqual(props.activeFilter, props.filter),
  });
  return (
    <li className={classes}>
      <button onClick={() => props.btnClickHandler(props.filter, props.caption)}>
        {props.caption}
      </button>
    </li>
  );
};

FilterMenuButton.propTypes = {
  activeFilter: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  btnClickHandler: PropTypes.func,
  filter: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  caption: PropTypes.string,
};

FilterMenuButton.defaultProps = {
  activeFilter: '',
  btnClickHandler: () => {},
  caption: '',
};

FilterMenuButton.displayName = 'FilterMenuButton';

export default StyledComponent(FilterMenu, [css]);
